<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                      <ValidationProvider name="Table" vid='warehouse_type_id' rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="warehouse_type_id"
                    slot-scope="{ valid, errors }"
                    >
                     <template v-slot:label>
                      {{ $t('globalTrans.selectTable')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="table_id"
                      :options="tableTypeList"
                      id="warehouse_type_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                    <ValidationProvider name="Attachment file" vid="excel_data">
                        <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="warehouse_attachments"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                            {{ $t('globalTrans.excelFile')}}<span class="text-danger">*</span>
                        </template>
                        <b-form-file
                        id="excel_data"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        v-on:change="onFileChange"
                        :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-file>
                        <div class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                        </b-form-group>
                    </ValidationProvider>
                    <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2" :disabled="isLoading">
                            <span v-if="isLoading"><b-spinner small></b-spinner> {{ $t('globalTrans.loading') }}</span>
                            <span v-else>{{ saveBtnName }}</span>
                        </b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-5')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { warehouseServiceBaseUrl } from '../../../../../config/api_config'
import { warehouseDataExcelStore } from '../../api/routes'
import { SpinnerPlugin } from 'bootstrap-vue'
import Vue from 'vue'
Vue.use(SpinnerPlugin)

export default {
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getWarehouseGroupData()
      this.warehouseGroup = tmp
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.$t('globalTrans.save'),
      excel_data: '',
      table_id: 0,
      attachment_two: '',
      isLoading: false
    }
  },
  computed: {
      tableTypeList () {
         const dataen = [
             {
                text: 'Crop Type',
                value: 1
            },
            {
                text: 'Crop Name',
                value: 2
            },
            {
                text: 'Region Name',
                value: 3
            },
            {
                text: 'Warehouse Information',
                value: 4
            },
            {
                text: 'Warehouse Level',
                value: 5
            },
            {
                text: 'Warehouse Type',
                value: 6
            },
            {
                text: 'Warehouse Designation',
                value: 7
            },
            {
                text: 'Warehouse Rent',
                value: 8
            },
            {
                text: 'Warehouse Service',
                value: 9
            },
            // {
            //     text: 'Bank Information',
            //     value: 10
            // },
            // {
            //     text: 'Branch Information',
            //     value: 11
            // },
            {
                text: 'Loan Rate',
                value: 12
            },
            {
                text: 'Opening Balence',
                value: 13
            },
            {
                text: 'Loan Rate Facility',
                value: 14
            },
            {
                text: 'Market Price',
                value: 15
            }
         ]
         const databn = [
             {
                text: 'শস্যের ধরণ',
                value: 1
            },
            {
                text: 'শস্যের নাম',
                value: 2
            },
            {
                text: 'অঞ্চল',
                value: 3
            },
            {
                text: 'গুদাম',
                value: 4
            },
            {
                text: 'গুদাম স্তর',
                value: 5
            },
            {
                text: 'গুদাম ধরণ',
                value: 6
            },
            {
                text: 'গুদাম পদবি',
                value: 7
            },
            {
                text: 'গুদাম ভাড়া',
                value: 8
            },
            {
                text: 'গুদাম সেবা',
                value: 9
            },
            // {
            //     text: 'ব্যাংক তথ্য',
            //     value: 10
            // },
            // {
            //     text: 'শাখা তথ্য',
            //     value: 11
            // },
            {
                text: 'ঋণের হার',
                value: 12
            },
            {
                text: 'প্রারম্ভিক ব্যালেন্স',
                value: 13
            },
            {
                text: 'ব্যাংক ঋণ সুবিধা',
                value: 14
            },
            {
                text: 'বাজারদর',
                value: 15
            }
         ]
         const data = this.$i18n.locale === 'bn' ? databn : dataen
         return data
      }
  },
  methods: {
    onFileChange (e) {
      this.attachment_two = e.target.files[0]
    },
    getWarehouseGroupData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
     async createData () {
         this.isLoading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      var formData = new FormData()
      formData.append('table_id', this.table_id)
      formData.append('excel_file', this.attachment_two)

        result = await RestApi.postData(warehouseServiceBaseUrl, warehouseDataExcelStore, formData, config)

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('mutateWarehouseProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.$t('globalTrans.excelImportMessage'),
          color: '#D6E09B'
        })
         this.isLoading = false
        this.$bvModal.hide('modal-5')
      } else {
          if (result.errors) {
              this.$refs.form.setErrors(result.errors)
          } else {
            this.$toast.error({
                title: 'Error',
                message: result.message,
                color: '#D6E09B'
            })
          }
      }
    }
  }
}
</script>
