<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{$t('globalTrans.excelImport')}}</h4>
            </template>
        </iq-card>
        <iq-card>
            <template v-slot:body>
                <b-row>
                    <b-col></b-col>
                    <b-col>
                        <b-button variant="success" class="text-center" v-b-modal.modal-5>
                             {{  $t('globalTrans.excelImport') }}
                        </b-button>
                    </b-col>
                    <b-col></b-col>
                </b-row>
            </template>
        </iq-card>
        <b-modal id="modal-5" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <XlForm :id="editItemId" :key="editItemId"/>
        </b-modal>

    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import XlForm from './ExcelForm'
import RestApi, { warehouseServiceBaseUrl } from '../../../../../config/api_config'
import { warehouseGroupList, warehouseGroupToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
    mixins: [ModalBaseMasterList],
    components: {
       XlForm
    },
    data () {
        return {
            search: {
                croptypeID: 0
            }
        }
    },
    computed: {
        formTitle () {
            return this.$t('globalTrans.excelImport')
        }
    },
    async created () {
        this.loadData()
    },
    mounted () {
        core.index()
    },
    methods: {
        dataChange () {
            this.loadData()
        },
        default () {
            return {
                id: this.rows.length,
                commodity_group_name: '',
                commodity_group_name_bn: '',
                editable: false
            }
        },
        async searchData () {
            this.loadData()
        },
        remove (item) {
        this.changeStatus(warehouseServiceBaseUrl, warehouseGroupToggleStatus, item, 'warehosue', 'commodityGroupList')
        },
        loadData () {
            var croptypeIdSerach = ''
            if (this.search.croptypeID) {
                croptypeIdSerach = this.search.croptypeID.value
            }
            const params = Object.assign({}, this.search, { cropType_id: croptypeIdSerach, page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(warehouseServiceBaseUrl, warehouseGroupList, params).then(response => {
                if (response.success) {
                    this.$store.dispatch('setList', response.data.data)
                    this.paginationData(response.data)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        }
    }
}
</script>
